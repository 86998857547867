import "bootstrap"
import React from 'react'
import ReactDOM from 'react-dom'
e = React.createElement

$ = document.querySelector.bind(document)
$$ = document.querySelectorAll.bind(document)

# Globals
loggedIn = false

window.onSubmit = (token) ->
  $("form#search").submit()

# Sponsor Link Tracking
$$("a.sponsor").forEach((item) ->
  return item.addEventListener("click", (e) ->
    cbString = "/cb/?path=#{window.location.pathname}&sponsor=#{e.currentTarget.classList[0]}"
    fetch(cbString, {credentials: "same-origin"}).finally(() ->
      window.location = e.currentTarget.href
      return true
    )
  )
)

searchForm = $("form#search")
if searchForm
  searchForm.addEventListener("submit", (event, skipRecaptcha=false) ->
    unless grecaptcha?
      return true

    if loggedIn
      return true

    if skipRecaptcha
      return true

    event.preventDefault()
    grecaptcha.execute()
  )

$("span#version").textContent = __VERSION__

fetch("/user/username/", {credentials: "same-origin"}).then((data) ->
  unless data.status is 200
    throw new Error("Not logged in")
  return data.json()
).then((user) ->
  if user.isPro
    $$(".proonly").forEach((item) ->
      item.classList.add("inlineblock")
      item.classList.remove("displaynone")
    )
  loggedIn = true
  $("span#username").textContent = user.firstname
  $("a.loggedin").classList.add("inlineblock")
  $("a.loggedin").classList.remove("displaynone")
  $("a.loggedout").classList.add("displaynone")
  $("a.loggedout").classList.remove("inlineblock")
).catch(() ->
  return
)

if hljs?
  hljs.configure {tabReplace: "  "}
  hljs.highlightAll()

get_stats = () ->
  fetch("/stats", {credentials: "same-origin"}).then((data) ->
    return data.json()
  ).then((stats) ->
    $('#stats-running').textContent = stats.running + " Scans Running"
    $('#stats-queued').textContent = stats.queued + " Scans Queued"
    $('#stats-public').textContent = stats.public + " Public (24h)"
    $('#stats-unlisted').textContent = stats.unlisted + " Unlisted (24h)"
    $('#stats-private').textContent = stats.private + " Private (24h)"
    return
  )

setInterval(() ->
  get_stats()
, 20000)
get_stats()

$$("img.ha").forEach((item) ->
  item.onerror = () ->
    $("div.haresult").classList.add("displaynone")
    $(".hanotfound").classList.remove("displaynone")
  item.src = item.getAttribute("data-src")
)

####################
# Path-Specific Code
####################

# /result/.*/dom/
if document.location.pathname.match(/^\/result\/.*\/dom\//)
  uuid = $("#uuid").getAttribute("data-uuid") 
  fetch("/dom/#{uuid}/", {credentials: "same-origin"}).then((data) ->
    return data.text()
  ).then((data) ->
    $("#dom-buttons").classList.remove("hidden")
    if hljs?
      $("#dom").innerHTML = hljs.highlight(html_beautify(data, {indent_size: 2}), {language: "html"}).value
  ).catch((err) ->
    $("#dom-buttons").classList.add("hidden")
    $("#dom").textContent = "Could not fetch the DOM document. The DOM might not always be available."
  ).finally(() ->
    $("#domprogress").classList.add("displaynone")
  )

# /result/.*/loading/
else if document.location.pathname.match(/^\/result\/.*\/loading/)
  interval = 2000
  attempts = 0

  checkComplete = ->
    fetch(window.location.pathname.replace('loading', 'state'), {credentials: "same-origin"}).then((response) ->
      if response.status is 400
        return undefined
      else
        return response.json()
    ).then((response) ->
      if response and response.uuid
        window.location.pathname = window.location.pathname.replace('/loading', '/')
      return undefined
    ).finally(() ->
      attempts+=1
      interval+=500
      unless attempts > 20
        setTimeout(checkComplete, interval)
      return
    )

  setTimeout(checkComplete, 10000)

# /live/
else if document.location.pathname.match(/^\/live\//)
  results_array = []
  error_message = undefined
  seconds = 10

  rootElement = (props) ->
    e('div', {className: "pad5"}, props.results)

  chunk = (arr, size) ->
    Array.from({length: Math.ceil(arr.length / size) }, (v, i) ->
      arr.slice(i * size, i * size + size)
    )

  load_live = (query, add = false) ->
    fetch('/json/live/').then((data) ->
      return data.json()
    ).then((data) ->
      results_array = chunk(data.results, 4).map((c,i) ->
        e("div", {className: "row", key: i},
          c.map((d) ->
            e("div", {className: "col col-sm-3 live-tile", key: d['_id']},
              e("a", {href: "/result/#{d["_id"]}/"},
                e("img", {src: "/screenshots/#{d["_id"]}.png", title: d.page.domain, className: "screenshot-small minheight19525px"}),
                e("p", {className: "abbrev"},
                  e('span', className: 'flag-icon flag-icon-' + (d.page.country or '').toLowerCase()),
                  e('small', {}, " " + d.page.domain.slice(0, 35) + (if d.page.domain.length > 35 then '...' else ''))
                )
              )
            )
          )
        )
      )
      return
    ).catch((err) ->
      error_message = err.responseJSON.message
      return
    ).finally((err) ->
      ReactDOM.render rootElement({results: results_array}), document.getElementById('scans')
      return
    )
    return

  load_live()
  setInterval(() ->
    load_live()
  , seconds * 1000)
